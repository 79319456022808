<!--
 * @Author: goodLiang
 * @Date: 2023-12-04 17:42:25
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-06-28 16:41:11
 * @FilePath: /business-cms/src/views/manage/sell/detail.vue
-->
<template>
  <div class="container">
    <div class="inner">
      <CustomCard>
        <SubHead>订单状态 </SubHead>

        <el-steps :active="hasLight" :align-center="true">
          <el-step
            v-for="item in detailInfo.order_status_list"
            :key="item.node_time"
            :description="item.node_time"
            ><template #title
              ><el-link
                type="primary"
                :underline="item.refund_service_id"
                @click="handleToService(item.refund_service_id)"
                >{{ item.order_status_name }}</el-link
              ></template
            ></el-step
          >
        </el-steps>
        <SubHead> 订单信息</SubHead>
        <el-divider></el-divider>
        <div class="goods-info">
          <div class="header">
            <div class="header-left">
              <div>
                订单号：{{ detailInfo.order_info.order_id
                }}<span class="mr20 ml20"></span>
              </div>
              <div>
                下单时间：{{ detailInfo.order_info.order_submit_time
                }}<span class="mr20 ml20"></span>
              </div>
              <div>
                支付时间：{{ detailInfo.order_info.order_pay_time
                }}<span class="mr20 ml20">｜</span>
              </div>
              <div>
                {{ detailInfo.order_info.send_type_value }}
                <span style="color: #999"> ｜ </span>
                {{ detailInfo.order_info.platform_type_value }}
                <span v-show="detailInfo.order_info.pay_method_id">
                  <span style="color: #999"> ｜ </span>
                  {{ detailInfo.order_info.pay_method_value }}
                </span>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="table-header">
              <div class="column" style="width: 30%">商品信息</div>
              <div class="column" style="width: 13%">售价(元)</div>
              <div class="column" style="width: 13%">数量</div>
              <div class="column" style="width: 18%">商品总金额(元)</div>
              <div class="column" style="width: 13%">转账手续费</div>
              <div class="column" style="width: 13%">平台佣金</div>
              <div class="column" style="width: 13%">运费(元)</div>
            </div>
            <div class="detail">
              <div class="column goods" style="width: 30%">
                <img :src="detailInfo.order_info.commodity_image_url" alt="" />
                <div class="info">
                  <div class="title">
                    {{ detailInfo.order_info.commodity_name }}
                  </div>
                  <div class="code">
                    商品编码：{{ detailInfo.order_info.sku }}
                  </div>
                </div>
              </div>
              <div class="column" style="width: 13%; font-weight: 500">
                ¥{{ detailInfo.order_info.sale_amount }}
              </div>
              <div class="column" style="width: 13%">
                {{ detailInfo.order_info.sale_number }}
              </div>
              <div class="column" style="width: 18%; font-weight: 500">
                ¥{{ detailInfo.order_info.original_sale_amount }}
              </div>
              <div
                class="column"
                style="
                  width: 13%;
                  font-weight: 500;
                  display: block;
                  text-align: center;
                "
              >
                <p style="margin-top: 30px">
                  {{ detailInfo.order_info.transfer_fee / 100 }}
                </p>
                <p>
                  （费率：{{
                    detailInfo.order_info.transfer_fee_percent * 100
                  }}%）
                </p>
              </div>
              <div
                class="column"
                style="
                  width: 13%;
                  font-weight: 500;
                  display: block;
                  text-align: center;
                "
              >
                <p style="margin-top: 30px">
                  {{ detailInfo.order_info.platform_commission / 100 }}
                </p>
                <p>
                  （费率：{{
                    detailInfo.order_info.platform_commission_percent * 100
                  }}%）
                </p>
              </div>
              <div class="column" style="width: 13%; font-weight: 500">
                ¥{{ detailInfo.order_info.post_amount }}
              </div>
            </div>
          </div>
          <div class="price-box">
            <span class="txt">商家实收：</span>
            <span class="price">¥{{ detailInfo.order_info.pay_amount }}</span>
          </div>
        </div>
        <SubHead>收货信息 </SubHead>
        <el-divider></el-divider>
        <el-form label-position="left" label-width="110px">
          <el-form-item label="买家会员昵称">{{
            detailInfo.order_address.user_name
          }}</el-form-item>
          <el-form-item label="收货人">{{
            detailInfo.order_address.consignee
          }}</el-form-item>
          <el-form-item label="手机号">{{
            detailInfo.order_address.mobile
          }}</el-form-item>
          <el-form-item label="收货地址">{{
            detailInfo.order_address.address
          }}</el-form-item>
        </el-form>
        <SubHead>物流信息 </SubHead>
        <el-divider></el-divider>
        <template v-if="requestFinish && sendType == 1">
          <LogisticesInfo
            :showLogisticsList="detailInfo && detailInfo.logistics_list"
          />
        </template>
        <SubHead>服务信息 </SubHead>
        <el-divider></el-divider>
        <el-form label-position="left" label-width="110px">
          <el-form-item label="是否支持开票">{{
            detailInfo.order_server.has_support_invoice_value
          }}</el-form-item>
          <el-form-item label="开票状态">{{
            detailInfo.order_server.invoice_status
          }}</el-form-item>
        </el-form>
      </CustomCard>

      <div style="padding: 0 30px" v-if="sendType == 1">
        <el-divider></el-divider>
      </div>
    </div>
    <!-- <el-button plain @click="centerDialogVisible = true">
      Click to open the Dialog
    </el-button>

    <el-dialog v-model="centerDialogVisible" title="Warning" width="500" center>
      <span>
        It should be noted that the content will not be aligned in center by
        default
      </span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog> -->
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import CustomCard from '@/components/CustomCard'
import LogisticesInfo from '@/components/LogisticsInfo'
import { useRoute, useRouter } from 'vue-router'
import { popOrderDetail } from '@/api/jiushi'

const { query } = useRoute()
// const centerDialogVisible = ref(false)
const router = useRouter()
const requestFinish = ref(false)
const hasLight = ref(0)
const sendType = ref(query.sendType)
const detailInfo = reactive({
  order_status_list: [],
  order_info: {},
  order_address: {},
  logistics_list: [],
  order_server: {}
})

onMounted(() => {
  popOrderDetail({
    order_id: query.orderId
  }).then((res) => {
    const {
      order_status_list,
      order_info,
      order_address,
      logistics_list,
      order_server
    } = res

    let status = true
    order_status_list.find((item, index) => {
      if (item.has_light === 0) {
        status = false
        hasLight.value = index > 0 ? index - 1 : index
      }
    })
    if (status) hasLight.value = order_status_list.length - 1

    if (order_info.platform_type_id === 1) {
      order_info.platform_type_value = 'APP'
    }
    if (order_info.platform_type_id === 2) {
      order_info.platform_type_value = '小程序'
    }
    if (order_info.send_type === 1) order_info.send_type_value = '物流发货'
    if (order_info.send_type === 2) order_info.send_type_value = '存入酒库'
    if (order_info.send_type === 3) order_info.send_type_value = '自提'
    if (order_info.pay_method_id === 1) order_info.pay_method_value = '钱包支付'
    if (order_info.pay_method_id === 2) order_info.pay_method_value = '微信支付'
    if (order_info.pay_method_id === 3) order_info.pay_method_value = '对公转账'
    if (order_info.pay_method_id === 12) {
      order_info.pay_method_value = '支付宝支付'
    }
    if (order_info.pay_method_id === 14) {
      order_info.pay_method_value = '支付单拆分支付'
    }
    if (order_info.pay_method_id === 15) {
      order_info.pay_method_value = '银行转账'
    }
    if (order_server.has_support_invoice === 0) {
      order_server.has_support_invoice_value = '否'
    }
    if (order_server.has_support_invoice === 1) {
      order_server.has_support_invoice_value = '是'
    }
    order_info.pay_amount = (order_info.pay_amount / 100).toFixed(2)
    order_info.sale_amount = (order_info.sale_amount / 100).toFixed(2)
    order_info.post_amount = (order_info.post_amount / 100).toFixed(2)
    order_info.original_sale_amount = (
      order_info.original_sale_amount / 100
    ).toFixed(2)

    detailInfo.order_status_list = order_status_list
    detailInfo.order_info = order_info
    detailInfo.order_address = order_address
    detailInfo.logistics_list = logistics_list
    detailInfo.order_server = order_server

    requestFinish.value = true
  })
})
const handleToService = (id) => {
  if (id) {
    router.push(`/manage/service/detail/${id}`)
  }
}
</script>

<style lang="scss" scoped>
.container {
  .inner {
    background: #ffffff;
    border-radius: 4px;
  }

  .goods-info {
    width: 100%;
    background: #ffffff;
    margin-bottom: 30px;

    .header {
      height: 48px;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left {
        display: flex;
      }
    }

    .content {
      width: 100%;
      border-radius: 2px;
      border: 1px solid #dce6f0;

      .table-header {
        width: 100%;
        background: #f3f5f7;
        border-bottom: 1px solid #dce6f0;
        display: flex;

        .column {
          box-sizing: border-box;
          border-right: 1px solid #dce6f0;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #484d52;
        }

        .column:last-child {
          border-right: 1px solid transparent;
        }
      }

      .detail {
        display: flex;

        .column {
          height: 105px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #dce6f0;
          font-size: 14px;
        }

        .column:last-child {
          border-right: 1px solid transparent;
        }

        .goods {
          img {
            width: 64px;
            height: 64px;
            background: #c4c4c4;
            margin-right: 8px;
          }

          .info {
            width: 58%;
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              width: 100%;
              height: 32.5px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #262626;
            }

            .code {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #484d52;
            }
          }
        }
      }
    }

    .price-box {
      margin-top: 20px;
      text-align: right;

      .txt {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #262626;
      }

      .price {
        font-size: 18px;
        font-weight: 500;
        color: #ef2a31;
      }
    }

    .mr20 {
      margin-right: 20px;
    }

    .ml20 {
      margin-left: 20px;
      color: #999;
    }
  }
}
:deep .el-step__head.is-finish {
  color: #0071db;
  border-color: #0071db;
}
:deep .el-step__title.is-finish {
  color: #0071db;
}
:deep .el-step__description.is-finish {
  color: #0071db;
}
:deep .el-step__head.is-process {
  color: #0071db;
  border-color: #0071db;
}
:deep .el-step__title.is-process {
  color: #0071db;
  font-weight: 400;
}
:deep .is-finish .el-step__line {
  background-color: #0071db;
}
</style>
